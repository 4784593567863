import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"

const FAQPage = ({ data }) => (
  <div className="pageContent base--shiftContent">
    <SEO title="FAQ" keywords={[`Sioux Lookout`, `hockey`, `FAQ`, `info`]} />
    <h1 className="base__pageTitle">Frequently Asked Questions</h1>

    <h2 style={{ marginTop: "50px" }}>
      ~This page is currently under development~
    </h2>
  </div>
)

export default FAQPage

export const pageQuery = graphql`
  query faqQuery {
    faqImg: file(relativePath: { eq: "sampleImage.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
